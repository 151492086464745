import Webamp from "webamp"; // eslint-disable-line import/no-unresolved
// This import gives us a URL to llama.mp3


import bootleg3k from "./bootleg3k.wsz";
import ArielPink from "./ArielPink 1.wsz";
import Sanko from "./sanko.wsz";
import bonkler from "./tracks/Bonkler your Jankler.mp3";
import enemies from "./tracks/I Dont need enemies.mp3";
import fortress from "./tracks/IceFortress.mp3";
import bieber from "./tracks/KroyClub - Cumsquirt - 08 08 Vintage Bieber.mp3";
import midas from "./tracks/OSCOB - FOLKLORE - 05 midas touch.mp3";
import gutter from "./tracks/LA RATZ - GutterPuke Vomitsauce.mp3";
import legit from "./tracks/KOOL SKULL - Thrash Prophet - 06 2Legit (Feat. DVS Donyea Da Bombay).mp3";
import cr8zmf from "./tracks/KOOL SKULL - Ow2bkool II - 02 Cr8zmf (feat. Ariel Pink).mp3";
import unmomento from "./tracks/LA RATZ - Uno Momento.mp3";
import alienbass from "./tracks/M dot Strange - ALL_CAPS - 03 ALIEN_BASS.mp3";
import agree from "./tracks/KOOL SKULL - Thrash Prophet - 03 Have 2 Agree (Feat. Tripp Vomit).mp3";
import fool from "./tracks/KOOL SKULL - Ow2bkool II - 03 Don’t Kill the Fool.mp3";
import fullmetal from "./tracks/KroyClub - Cumsquirt - 11 11 Full Metal Jacket.mp3";
import mask from "./tracks/Menstrual Mask.mp3";
import trumpnigga from "./tracks/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 12 TRUMP IS MY NIGGA.mp3";
import hc from "./tracks/KOOL SKULL - Ow2bkool II - 14 Hollywood Cowboy.mp3";
import teeth from "./tracks/KOOL SKULL - Thrash Prophet - 01 Brush Yo Teeth.mp3";
import BITCH from "./tracks/M dot Strange - ALL_CAPS - 04 BITCH.mp3";
import mygirl from "./tracks/mygirl.mp3";
import freesixnine from "./tracks/KOOL SKULL - Bestowing Grace Through Creation - 02 Free 6ix9ine (feat. Kroyclub).mp3";
import door from "./tracks/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 13 The Door Will Appear (feat. Kenny Lion).mp3";
import time from "./tracks/KOOL SKULL - Thrash Prophet - 08 Penetrating Time.mp3";
import lunch from "./tracks/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 02 Lunch in Purgatory (feat. Ariel Pink).mp3";
import wentoken from "./tracks/Strangetoshi Nakamanly - MANLY - 08 Wen Token.mp3";
import xplosive from "./tracks/KOOL SKULL - Ow2bkool II - 05 Xplosive.mp3";
import dinner from "./tracks/KOOL SKULL - Cybertemplar Audioweaponz 12212020 - 11 Dinner in Heaven (feat. Ariel Pink).mp3";
import early from "./tracks/Strangetoshi Nakamanly - MANLY - 09 Early.mp3";



if (!Webamp.browserIsSupported()) {
  alert("Oh no! Webamp does not work in this browser!");
  throw new Error("What's the point of anything?");
}

const webamp = new Webamp({


  initialTracks: [
    {
      metaData: {
        artist: "KOOLSKULL (Feat. MiyaGod)",
        title: "Bonkler you Jankler"
      },
      url: bonkler,
      duration: 4.16
    },
    {
      metaData: {
        artist: "LA Ratz",
        title: "Gutterpuke Vomitsauce"
      },
      url: gutter,
      duration: 0.44
    },
    {
      metaData: {
        artist: "Ariel Pink (Feat. Donald Trump)",
        title: "I Don't Need Enemies"
      },
      url: enemies,
      duration: 3.2665
    },
    { 
      metaData: {
        artist: "KOOL SKULL",
        title: "Ice Fortress"
      },
      url: fortress,
      duration: 4.27
    },
    { 
      metaData: {
        artist: "Kroyclub",
        title: "Vintage Bieber"
      },
      url: bieber,
      duration: 5.14
    },
    { 
      metaData: {
        artist: "OSCOB",
        title: "Midas Touch"
      },
      url: midas,
      duration: 5.25
    },
    { 
      metaData: {
        artist: "KOOL SKULL (feat. Ariel Pink)",
        title: "Cr8zMF"
      },
      url: cr8zmf,
      duration: 3.29
    },
    { 
      metaData: {
        artist: "KOOL SKULL (Feat. DVS Donyea Da Bombay)",
        title: "2Legit"
      },
      url: legit,
      duration: 2.18
    },
    { 
      metaData: {
        artist: "KOOL SKULL",
        title: "Trump is my Nigga"
      },
      url: trumpnigga,
      duration: 2.55
    },
    {
      metaData: {
        artist: "LA Ratz",
        title: "Un Momento"
      },
      url: unmomento,
      duration: 1.54
    },
    {
      metaData: {
        artist: "M dot Strange",
        title: "ALIEN_BASS"
      },
      url: alienbass,
      duration: 2.33
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Tripp Vomit",
        title: "Have 2 Agree"
      },
      url: agree,
      duration: 3.39
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Jean Mors)",
        title: "Don’t Kill the Fool"
      },
      url: fool,
      duration: 2.18
    },
    {
      metaData: {
        artist: "Kroyclub",
        title: "Full Metal Jacket"
      },
      url: fullmetal,
      duration: 5.10
    },
    {
      metaData: {
        artist: "Ariel Pink (feat. Jean Mors)",
        title: "Menstural Mask"
      },
      url: mask,
      duration: 2.24
    },
    {
      metaData: {
        artist: "KOOL SKULL",
        title: "Hollywood Cowboy"
      },
      url: hc,
      duration: 1.54
    },
    {
      metaData: {
        artist: "KOOL SKULL",
        title: "Brush Yo Teeth"
      },
      url: teeth,
      duration: 2.41
    },
    {
      metaData: {
        artist: "M dot Strange",
        title: "BITCH"
      },
      url: BITCH,
      duration: 3.08
    },
    {
      metaData: {
        artist: "Ariel Pink (feat. Donald Trump",
        title: "My Girl"
      },
      url: mygirl,
      duration: 3.42
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Kroyclub)",
        title: "Free 6ix9ine"
      },
      url: freesixnine,
      duration: 3.30
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Kenny Lion)",
        title: "The Door Will Appear"
      },
      url: door,
      duration: 2.41
    },
    {
      metaData: {
        artist: "KOOL SKULL",
        title: "Penetrating TIme"
      },
      url: time,
      duration: 2.37
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Ariel Pink)",
        title: "Lunch in Purgatory"
      },
      url: lunch,
      duration: 4.20
    },
    {
      metaData: {
        artist: "Satoshi Nakamanly",
        title: "Wen Token"
      },
      url: wentoken,
      duration: 2.05
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Jean Mors)",
        title: "Xplosive"
      },
      url: xplosive,
      duration: 2.02
    },
    {
      metaData: {
        artist: "KOOL SKULL (feat. Ariel Pink)",
        title: "Dinner in Heaven"
      },
      url: dinner,
      duration: 4.08
    },
    {
      metaData: {
        artist: "Satoshi Nakamanly",
        title: "Early"
      },
      url: early,
      duration: 3.07
    }
  ],
  
  availableSkins: [
    { url: bootleg3k, name: "BOOTLEG3000" },
    { url: ArielPink, name: "Ariel Pink"},
    { url: Sanko, name: "Sanko"},
  ]
});

// An example of handling the close button and giving a way to reopen WEebamp
webamp.onClose(() => {
  const open = document.createElement("button");
  open.innerText = "Reopen";
  document.getElementById("controls").appendChild(open);
  function handleClick() {
    webamp.reopen();
    open.remove();
    open.removeEventListener("click", handleClick);
  }
  open.addEventListener("click", handleClick);
});

// Example of adding a confirmation button before Webamp can be closed.
webamp.onWillClose((cancel) => {
  if (!window.confirm("Are you sure you want to close Webamp?")) {
    cancel();
  }
});

// An example of adding external ways to control Webamp's behavior.
const buttonBindings = [
  { id: "prev", cb: () => webamp.previousTrack() },
  { id: "play", cb: () => webamp.play() },
  { id: "pause", cb: () => webamp.pause() },
  { id: "stop", cb: () => webamp.stop() },
  { id: "next", cb: () => webamp.nextTrack() },
  { id: "close", cb: () => webamp.close() }
];
buttonBindings.forEach(({ id, cb }) => {
  document.getElementById(id).addEventListener("click", cb);
});

// An example of shoing the currently playing track
webamp.onTrackDidChange((trackInfo) => {
  const { artist, title } = trackInfo.metaData;
  // Another option might be to set `document.title`;
  document.getElementById(
    "current-track"
  ).innerText = `Now playing: ${title} by ${artist}`;
});

// Here we go! Let's show Webamp.
webamp.renderWhenReady(document.getElementById("app"));
